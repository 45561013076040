import firebase from "firebase";

import "firebase/auth"; //auth
import "firebase/database"; //database can like sql , mongodb //firebase is no sql type
import "firebase/storage"; //storage ///images , videos , photos , pdf , fonts

const firebaseConfig = {
  apiKey: "AIzaSyALInLaxOiNUWrdJQ_IOjJDrRwc2C4Ksqk",
  authDomain: "mvltv-99e5a.firebaseapp.com",
  databaseURL: "https://mvltv-99e5a.firebaseio.com",
  projectId: "mvltv-99e5a",
  storageBucket: "mvltv-99e5a.appspot.com",
  messagingSenderId: "944212467140",
  appId: "1:944212467140:web:33607677230838e3db9891",
  measurementId: "G-XKK5ZXRD43"
};

//initialize firebase and react application
firebase.initializeApp(firebaseConfig);

export default firebase;
