import React, { Component, Fragment } from "react";
import { withRouter,Link } from "react-router-dom";



class NotFoundPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  async componentDidMount() {
  }

  render() {
    return (
      <Fragment>
        <div className="watch-area">
        <div className="content-wrapper">
            <div>
                <img style={{textAlign:"center",margin:"20px",marginTop:"50px"}} src={"https://firebasestorage.googleapis.com/v0/b/mvltv-99e5a.appspot.com/o/thumbnail%2Fmirzapur-s1%2F404.png?alt=media&token=8b1a3226-b0b2-4974-a7c1-ee15c6c5e188"} alt="404"></img>
            </div>
            <h3 style={{textAlign:"center"}}>404</h3>
            <p style={{textAlign:"center"}}>Oh! Page not found</p>
            <button type="button" className="home-btn" onClick={(e) => {
            window.location.href='/';
            }}>Disney+ Hotstar HOME</button>
            </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(NotFoundPage);
