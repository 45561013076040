import React, { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "../../firebase";
import "./Auth-Styles.css";


function SubscribePage (para) {
  let userData=para.user

  const [couponCode,setCouponCode]=useState("")

  const handleCouponCodeInput=(e)=>{
    let input=e.target.value
    input=input.split('')
    input = input.filter(function(item) {
      return !['-','%','$','#','@','!','*','^','(',')','&'].includes(item)
    })
    if(input.length<=12) {
      let final=""
      input.forEach((ch,i)=>{
        final=final+ch
        console.log((i+1)%4)
        if((i+1)%4===0&&input.length!==i+1) final=final+'-'
      })
      setCouponCode(final)
    }
  }

  const SubmitCoupon=()=>{
    if(couponCode.length<=12) {
      toast.error('Wrong Coupon Code',
      {
        position:'bottom-center'
      })
    }
    else{
      toast.dark('You are Now HotStar VIP',
      {
        position:'bottom-center'
      })
      setCouponCode("")
    }
  }


  return (
    <Fragment>
      <section className="authBlock">
        {/* profile top */}
        <div style={{textAlign:"center"}}>
          <img src={userData.photoURL} style={{borderRadius:"50%",marginBottom:"20px",width:"90px"}} alt={userData.displayName} />
          <h5 className="font-weight-bold">{userData.displayName}</h5>
          <p style={{color:"#CECFD1"}}>{userData.email}</p>
        </div>
        <div style={{background: "#1A2133",padding:"20px 20px 20px 20px",width:"95%",maxWidth:"450px",marginBottom:"2px"}}>
            <label htmlFor="phone">Enter Coupon Code</label>
            <input
              type="text"
              className="form-control"
              name="phone"
              value={couponCode}
              onChange={handleCouponCodeInput}
              required
            />
        </div>
        <div style={{background: "#1A2133",padding:"10px",width:"95%",maxWidth:"450px",marginBottom:"10px"}}>
              <button onClick={SubmitCoupon} className="btn btn-block btn-outline-primary">
                Submit Coupon
              </button>
        </div>
        <div className="isLink" style={{background: "#1A2133",padding:"20px 20px 0px 20px",width:"95%",maxWidth:"450px",marginBottom:"10px",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
          <p style={{color:"#CECFD1",fontSize:"small"}}>
            Use Another Account
          </p>
          <p style={{color:"#CECFD1",fontSize:"small"}}>
            {">"}
          </p>
        </div>
        <div className="isLink" style={{background: "#1A2133",padding:"20px 20px 0px 20px",width:"95%",maxWidth:"450px",marginBottom:"2px"}}>
              <p style={{color:"#CECFD1",fontSize:"small"}}>Log Out</p>
        </div>
        <div className="isLink" style={{background: "#1A2133",padding:"20px 20px 0px 20px",width:"95%",maxWidth:"450px",marginBottom:"2px"}}>
              <p style={{color:"#CECFD1",fontSize:"small"}}>How to Get Coupon?</p>
        </div>
      </section>
    </Fragment>
  );
}

export default SubscribePage;
