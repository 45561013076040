import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "../../firebase";
import "../AuthComponent/Auth-Styles.css";

function ProfilePage (para) {
  let userData=para.user
  console.log(userData)
  return (
    <Fragment>
      <section className="authBlock">
        {/* profile top */}
        <div style={{textAlign:"center"}}>
          <img src={userData.photoURL} style={{borderRadius:"50%",marginBottom:"20px",width:"90px"}} alt={userData.displayName} />
          <h5 className="font-weight-bold">{userData.displayName}</h5>
          <p style={{color:"#CECFD1"}}>{userData.email}</p>
        </div>
        <div style={{background: "#1A2133",padding:"20px 20px 0px 20px",width:"95%",maxWidth:"450px",marginBottom:"2px"}}>
          <h6 style={{color:"#CECFD1"}}>You have No Plan</h6>
          <p style={{color:"#898D93"}}>Buy Disney+ Hotstar VIP for ₹ 399/year</p>
        </div>
        <div style={{background: "#1A2133",padding:"10px",width:"95%",maxWidth:"450px",marginBottom:"10px"}}>
              <Link to='/subscribe'><button className="btn btn-block btn-outline-primary">
                Upgrade Plan
              </button>
              </Link>
        </div>
        <div style={{background: "#1A2133",padding:"20px 20px 0px 20px",width:"95%",maxWidth:"450px",marginBottom:"10px",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
          <p style={{color:"#CECFD1",fontSize:"small"}}>
            View Transaction
          </p>
          <p style={{color:"#CECFD1",fontSize:"small"}}>
            {">"}
          </p>
        </div>
        <div style={{background: "#1A2133",padding:"20px 20px 0px 20px",width:"95%",maxWidth:"450px",marginBottom:"2px"}}>
              <p style={{color:"#CECFD1",fontSize:"small"}}>Log Out</p>
        </div>
        <div style={{background: "#1A2133",padding:"20px 20px 0px 20px",width:"95%",maxWidth:"450px",marginBottom:"2px"}}>
              <p style={{color:"#CECFD1",fontSize:"small"}}>Log Out All Devices</p>
        </div>
      </section>
    </Fragment>
  );
}

export default ProfilePage;
